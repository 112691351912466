
import CONFIG from "../inc/Config";
const day = 1000*60*60*24;

export default class DateLib{
    static formatterDate(date, opt='en'){
        if(!date) return null;
        if(typeof date === 'string') date = new Date(date);
        if(!this.isValidDate(date)) return null;
        let day = date.toLocaleString("default", {day: '2-digit'});
        let month = date.toLocaleString("default", {month: '2-digit'});
        let year = date.toLocaleString("default", {year: 'numeric'});
        return opt === 'ru'
        ? `${day}.${month}.${year}` : `${year}-${month}-${day}`;
    }
    static formatterTime(date){
        if(!date) return null;
        if(typeof date == 'string') return date;
        return date.toLocaleString("ru", {
            hour: 'numeric',
            minute: 'numeric',
          })
    }

    static formatterDateTime(date, opt = 'ru', timeZone = CONFIG.timeZone) {
        if (!date) return null;
        if (typeof date === 'string') return date;
    
        if (opt === 'unix') return date.getTime();
    
        // Получаем значения с учетом временной зоны
        const options = {
            timeZone,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // 24-часовой формат
        };
    
        // Форматируем дату и время
        const formattedDateTime = date.toLocaleString('default', options);
        const [datePart, timePart] = formattedDateTime.split(', ');
    
        // Извлекаем день, месяц, год, часы, минуты и секунды
        const [day, month, year] = datePart.split('.').map(num => String(num).padStart(2, '0'));
        const [hours, minutes, seconds] = timePart.split(':').map(num => String(num).padStart(2, '0'));
        // Возвращаем форматированную строку в зависимости от опции
        return opt === 'ru'
            ? `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
            : `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Возвращаем формат для datetime-local
    }
    
    static getDayWeek(date){
        if(!date) return null;
        if(typeof date === 'string') date = new Date(date);
        switch(date.getDay()){
            case 0: return "Воскресенье";
            case 1: return "Понедельник";
            case 2: return "Вторник";
            case 3: return "Среда";
            case 4: return "Четверг";
            case 5: return "Пятница";
            case 6: return "Суббота";
            default: return null;
        }
    }
    static equals(date1, date2){
        return (DateLib.formatterDate(date1) === DateLib.formatterDate(date2))
    }
    static isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }
    static get day(){
        return day;
    }
    static now(type = 'en'){
        return DateLib.formatterDateTime(new Date(), type);
    }
}
