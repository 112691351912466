//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiSurvey from "../../../../../app/api/authorized/admin/client/ApiSurvey";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import Question from "../../../../components/survey/question/question";
import InputWrapper from "../../../../components/input/__wrapper";
import Select from "../../../../components/inputs/select";
//Стили
import './styles.css';

export default function SurveyShow({ ...other}){
    return <Page ApiModel={ApiSurvey} { ...other}>
      <SurveyShowContent { ...other}/>
    </Page>
}

function SurveyShowContent({ ...other }) {
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_data, set_data] = useState(null);
  const [survey, set_survey] = useState(null);
  const [_survey_type, set_survey_type] = useState(null);
  const [update_survey, set_update_survey] = useState(null);
  const [answers, set_answers] = useState({}); // Состояние для хранения ответов

  const surveyTypes = useSelector(state => state.adminOption.surveys);

  contextContent._dataToServer.current = {
    id: _id,
    survey_slug: _survey_type,
    answers: answers,
    update_survey: update_survey,
  };

  function visibleCreateButtonSwitch(is_visible){
    const createButton = document.querySelector('.content__footer-rigth');
    if(createButton){
      if (is_visible) {
        createButton.style.display = '';
      }else createButton.style.display = 'none';
    }
  }

  useLayoutEffect(() => {
    contextContent._handleSetData.current = (data) => {
      set_id(data.id);
      set_data(data);
      set_survey(data);
      set_survey_type(data.slug);
      set_update_survey(1);
      contextPage.setTitle(`Анкета клиента:  ${data.client_fullname}`);
      contextContent.setTitle(`Анкета клиента:  ${data.client_fullname}`);
      contextPage.setName(data.client_fullname);
    };
    contextContent.doLoad();
  }, []);
  
  const showSurveyTypesSelect = () => {
    if(!_data)
      return (
        <InputWrapper>
          <Select className="pages__show-input" options={surveyTypes} value={_survey_type} readOnly={!contextContent.isCreateOrEdit}  onChange={handleSurveyTypeChange}>Анкета</Select>
        </InputWrapper>
      )
  }

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const apiSurvey = ApiSurvey.Show(_survey_type);
        const responseData = await apiSurvey.getResponse();
        if (responseData) {
          set_survey(responseData);
        }
      } catch (error) {}
    };

    if(_survey_type) {
      fetchData();
      visibleCreateButtonSwitch(true)
    }
    else{
      set_answers({})
      set_survey_type(null)
      set_survey(null)
      visibleCreateButtonSwitch(false)
    }
  }, [_survey_type]);

  const handleSurveyTypeChange = (event) => {
    set_survey_type(event.target.value);
  } 

   // Функция для проверки видимости вопроса
  const getVisibilityClass = (question) => {
    if (!!question.parent_id && !Object.values(answers).includes(question.parent_id)) {
      return 'question-hidden';
    }
    return 'question';
  };

  const renderSurvey = () => {
    if (survey) {
      return (
        <>
          <h2>{survey.name}</h2>
          {survey.questions.map((question, index) => {
            const visibilityClass = getVisibilityClass(question); // Получаем класс видимости
            
            let clientAnswer = null;
            if(survey.client_answers){
               clientAnswer = survey.client_answers.find(answer => answer.question_id === question.id);
            }

            return (
              <div key={index} className={visibilityClass}>
                <Question 
                  clientAnswer={clientAnswer}
                  question={question} 
                  readOnly={!contextContent.isCreateOrEdit}
                  onOtherChange={(id, value) => handleRadioAnswerChange(id, value)} 
                  onOtherInputChange={(id, value) => handleInputAnswerChange(id, value)} 
                />
              </div>
            );
          })}
        </>
      );
    } 
  };

  const handleInputAnswerChange = (questionId, value) => {
    if(value){
      set_answers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: value,
      }));
    }else{
      set_answers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: null,
      }));
    }
  };

  const handleRadioAnswerChange = (questionId, value) => {
    if(value != 'freeAnswer' && value.trim != '') {
      set_answers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: Number(value),
      }));
    }
  };

  return <>
    {showSurveyTypesSelect()}
    {renderSurvey()}
  </>;
}
