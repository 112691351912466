//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiSurvey from "../../../../../app/api/authorized/admin/client/ApiSurvey";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import InputDate from "../../../../components/inputs/date";
import InputEmail from "../../../../components/inputs/email/email";
import Select from "../../../../components/inputs/select";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
//Material UI
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
//Стили
import './styles.css';

export default function ClientIndex({ ...other }) {
    return <Page {...other}>
        <ClientIndexContent {...other} />
    </Page>
}

function ClientIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_client_fullname, set_client_fullname] = useState('');
    const [_client_email, set_client_email] = useState('');
    const [_gender, set_gender] = useState('');
    const [_birthday, set_birthday] = useState('');
    const [_survey_name, set_survey_name] = useState('');
    const [_signature, set_signature] = useState('');
    const [_date_of_signature, set_date_of_signature] = useState('');
    const [_survey_type, set_survey_type] = useState(null);
    const [_created_by_user, set_created_by_user] = useState('');
    const [_updated_by_user, set_updated_by_user] = useState('');
    
    const surveyTypes = useSelector(state => state.adminOption.surveys);
    
    useLayoutEffect(() => {
        contextPage.setTitle(`Анкеты`);
        contextContent.setTitle(`Анкеты`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="client_fullname"
            name="client_fullname"
            isSort={false}
        >
            Клиент
        </ListHeaderItem>,
         <ListHeaderItem
            key="client_email"
            name="client_email"
            isSort={false}
        >
            Почта
         </ListHeaderItem>,
        <ListHeaderItem
            key="gender"
            name="gender"
            isSort={false}
        >
            Пол
        </ListHeaderItem>,
        <ListHeaderItem
            key="birthday"
            name="birthday"
            isSort={false}
        >
            Дата рождения
        </ListHeaderItem>,
        <ListHeaderItem
            key="survey_name"
            name="survey_name"
            isSort={false}
        >
            Анкета
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_signature"
            name="is_signature"
        >
            Подпись
        </ListHeaderItem>,
        <ListHeaderItem
            key="dateOfSignature"
            name="dateOfSignature"
            isSort={false}
        >
            Дата подписи
        </ListHeaderItem>,
        <ListHeaderItem
            key="created_by_user"
            name="created_by_user"
            isSort={false}
        >
            Кем создана
        </ListHeaderItem>,
        <ListHeaderItem
            key="updated_by_user"
            name="updated_by_user"
            isSort={false}
        >
            Кем обновлена
        </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
    ];

    const handleSurveyTypeChange = (event) => {
        set_survey_type(event.target.value);
      } 

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
        <InputText className="input_wf" value={_client_fullname} onChange={(e) => { set_client_fullname(e.target.value) }}>ФИО клиента</InputText>,
        <InputEmail className="input_wf" value={_client_email} onChange={(e) => { set_client_email(e.target.value) }}>Почта</InputEmail>,
        <InputDate className="input_wf" value={_birthday} onChange={(e) => { set_birthday(e.target.value) }}>Дата рождения</InputDate>,
        <Select className="input_wf" options={surveyTypes} value={_survey_type} onChange={handleSurveyTypeChange}>Анкета</Select>,
        <InputDate className="input_wf" value={_date_of_signature} onChange={(e) => { set_date_of_signature(e.target.value) }}>Дата подписи</InputDate>,
        <InputText className="input_wf" value={_created_by_user} onChange={(e) => { set_created_by_user(e.target.value) }}>Кем создана</InputText>,
        <InputText className="input_wf" value={_updated_by_user} onChange={(e) => { set_updated_by_user(e.target.value) }}>Кем собновлена</InputText>
    ];
    
    
    const renderRow = (row, i) => {
        return [
            <ListBodyItem key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem key="client_fullname" data-label={header[1].props.children}>{row.client_fullname}</ListBodyItem>,
            <ListBodyItem key="client_email" data-label={header[2].props.children}>{row.client_email}</ListBodyItem>,
            <ListBodyItem key="gender" data-label={header[3].props.children}>{row.gender}</ListBodyItem>,
            <ListBodyItem key="birthday" data-label={header[4].props.children}>{row.birthday}</ListBodyItem>,
            <ListBodyItem key="survey_name" data-label={header[5].props.children}>{row.survey_name}</ListBodyItem>,
            <ListBodyItem key="is_signature" data-label={header[6].props.children}>
                {
                    row.is_signature ? <div className="is-active_icon--success"><VerifiedIcon/></div>
                    : <div className="is-active_icon--danger"><NewReleasesIcon/></div> 
                }
            </ListBodyItem>,
            <ListBodyItem key="date_of_signature" data-label={header[7].props.children}>{row.date_of_signature}</ListBodyItem>,
            <ListBodyItem key="created_by_user" data-label={header[8].props.children}>{row.created_by_user}</ListBodyItem>,
            <ListBodyItem key="updated_by_user" data-label={header[9].props.children}>{row.updated_by_user}</ListBodyItem>,    
            <ListBodyItem key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiSurvey}
            renderRow={renderRow}
            searchParams={{ id: _id, client_fullname: _client_fullname, client_email: _client_email, birthday: _birthday, survey_type: _survey_type, dateOfSignature: _date_of_signature, created_by_user: _created_by_user, updated_by_user: _updated_by_user}}
        />
    </>
}