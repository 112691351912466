//Реакт
import React, { useState, useEffect, useRef } from "react";
//Компоненты
import Label from "../label";
import MessageError from "../../message/__errors";
//Matrial UI
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
//Стили
import './styles.css'

export default function Select({
    options = {}, value = null, labelNull = null,
    children, onChange, onTextChange = () => {}, placeholder="Поиск ...", errors, className = '',
    readOnly, ...other
}) {
    const [searchText, setSearchText] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [isListOpen, setIsListOpen] = useState(false);
    const [showAllOptions, setShowAllOptions] = useState(false);
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const selectWrapperRef = useRef(null);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (!isListOpen) {
            setIsFocused(false);
            if (!isOptionSelected) {
                setSearchText("");
            }
        }
    };

    const handleToggle = () => {
        setIsListOpen(!isListOpen);
    };

    const handleSelectOption = (selectedValue) => {
        onChange({ target: { value: selectedValue } });
        setSearchText(options[selectedValue]);
        setIsFocused(false);
        setIsListOpen(false);
        setShowAllOptions(true);
        setIsOptionSelected(true);
    };

    const handleInputChange = (value) => {
        setSearchText(value);
        onTextChange({target: {value: value}});
        setIsOptionSelected(false);
        setShowAllOptions(false);
    };

    const handleReset = () => {
        handleInputChange('');
        onChange({ target: { value: null } });
        setIsFocused(false);
        setIsListOpen(false);
        setShowAllOptions(true);
        setIsOptionSelected(true);
    }

    useEffect(() => {
        if (value in options) {
            setSearchText(options[value]);
            setShowAllOptions(true);
            setIsOptionSelected(true);
        }
    }, [value, options]);

    const filteredOptions = showAllOptions ? Object.keys(options) : Object.keys(options).filter(key => {
        return options[key].toLowerCase().includes(searchText.toLowerCase());
    });

    if (placeholder === undefined) placeholder = children;

    if (!(value in options)) {
        value = '';
        labelNull = '';
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectWrapperRef.current && !selectWrapperRef.current.contains(event.target)) {
                setIsListOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectWrapperRef]);

    return (
        <>
            <Label>{children}</Label>
            <div ref={selectWrapperRef} className={`select-wrapper ${className}`}>
                <input
                    type="text"
                    value={searchText}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onClick={handleToggle}
                    placeholder={placeholder}
                    className='input select-input'
                    readOnly={readOnly}
                    {...other}
                />  
                <button className={`select__reset-button ${readOnly ? 'select__reset-button--disabled' : ''}`} onClick={() => handleReset()}>
                    <DeleteOutlineOutlinedIcon />
                </button>
                {isFocused && (
                    <div className="options-dropdown">
                        {filteredOptions.map((key) => (
                            <div className='option-item' key={key} onClick={() => handleSelectOption(key)}>
                                {options[key]}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {errors ? <MessageError errors={errors} sx={{ width: "100%" }} /> : null}
        </>
    );
}