import TypeConst from "./consts";

export function setLangs(langs) {
    return {
        type: TypeConst.SET_LANGS,
        langs,
    }
}
export function setPermissions(permissions) {
    return {
        type: TypeConst.SET_PERMISSIONS,
        permissions,
    }
}
export function setRoles(roles) {
    return {
        type: TypeConst.SET_ROLES,
        roles,
    }
}
export function setProducts(products) {
    return {
        type: TypeConst.SET_PRODUCTS,
        products,
    }
}
export function setCategoryProducts(categoryProducts) {
    return {
        type: TypeConst.SET_CATEGORY_PRODUCTS,
        categoryProducts,
    }
}
export function setCompetitionProducts(competitionProducts) {
    return {
        type: TypeConst.SET_COMPETITION_PRODUCTS,
        competitionProducts,
    }
}
export function setProductCategories(productCategories) {
    return {
        type: TypeConst.SET_PRODUCT_CATEGORIES,
        productCategories,
    }
}
export function setPlatforms(platforms) {
    return {
        type: TypeConst.SET_PLATFORMS,
        platforms,
    }
}
export function setOrderTypes(orderTypes) {
    return {
        type: TypeConst.SET_ORDER_TYPES,
        orderTypes,
    }
}
export function setBannerTypes(bannerTypes) {
    return {
        type: TypeConst.SET_BANNER_TYPES,
        bannerTypes,
    }
}
export function setBannerStatuses(bannerStatuses) {
    return {
        type: TypeConst.SET_BANNER_STATUSES,
        bannerStatuses,
    }
}
export function setCalendarStatuses(calendarStatuses) {
    return {
        type: TypeConst.SET_CALENDAR_STATUSES,
        calendarStatuses,
    }
}
export function setPromocodes(promocodes) {
    return {
        type: TypeConst.SET_PROMOCODES,
        promocodes,
    }
}
export function setCompetitions(competitions) {
    return {
        type: TypeConst.SET_COMPETITIONS,
        competitions,
    }
}
export function setCompetitionTeamDisciplines(competitionTeamDisciplines){
    return {
        type: TypeConst.SET_COMPETITION_TEAM_DISCIPLINES,
        competitionTeamDisciplines,
    }
}
export function setPaymentStatusesCertificate(paymentStatusesCertificate){
    return {
        type: TypeConst.SET_PAYMENT_STATUSES_CERTIFICATE,
        paymentStatusesCertificate,
    }
}
export function setBookingTimeStatuses(bookingTimeStatuses) {
    return {
        type: TypeConst.SET_BOOKING_TIME_STATUSES,
        bookingTimeStatuses,
    }
}
export function setBookingTimeTypes(bookingTimeTypes) {
    return {
        type: TypeConst.SET_BOOKING_TIME_TYPES,
        bookingTimeTypes,
    }
}
export function setSurveys(surveys){
    return {
        type: TypeConst.SET_SURVEYS,
        surveys,
    }
}
