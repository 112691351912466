// React
import React, { useState, useEffect } from "react";
// Styles
import "./styles.css";
// Компоненты
import InputWrapper from "../../input/__wrapper/input__wrapper";

export default function RadioAnswers({ answers, clientAnswer, freeAnswer, readOnly, questionId, onOtherChange, onOtherInputChange }) {
  const [selected_value, set_selected_value] = useState('');
  const [free_answer_value, set_free_answer_value] = useState('');
  
  useEffect(() => {
    if (clientAnswer) {
      if (clientAnswer.answer_id) {
        set_selected_value(clientAnswer.answer_id);
        onOtherChange(clientAnswer.question_id, clientAnswer.answer_id);
      } else {
        set_free_answer_value(clientAnswer.value);
        set_selected_value('freeAnswer'); // Устанавливаем значение для свободного ответа
        onOtherInputChange(clientAnswer.question_id, clientAnswer.value);
      }
    }
  }, [clientAnswer]);

  const handleRadioChange = (event) => {
    const value = event.target.value;
    set_selected_value(value);
    if (value === 'freeAnswer') {
      onOtherInputChange(questionId, free_answer_value);
    } else {
      onOtherChange(event.target.name, event.target.value);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    set_free_answer_value(value);
    onOtherInputChange(event.target.name, value);
  };

  return (
    <div className="radio__answers">
      {answers.map((answer, index) => (
        <div key={answer.id}>
          <label className="dictionary_answers">
            <input 
              className="dictionary_answers__radio"
              type="radio" 
              name={questionId} 
              value={answer.id} 
              checked={selected_value == answer.id} // Устанавливаем checked на основе selected_value
              onChange={handleRadioChange}
              disabled={readOnly} 
            />
            <span className="dictionary_answers__radio-text">{answer.value}</span>
          </label>
          {freeAnswer && index === answers.length - 1 ? (
            <>
              <label className="free_answer__label">
                <input 
                  className="free_answer__radio"
                  type="radio" 
                  name={questionId} 
                  value="freeAnswer" 
                  checked={selected_value === 'freeAnswer'}
                  onChange={handleRadioChange} 
                  disabled={readOnly} 
                />
                <span className="free_answer__radio-text">Другое:</span>
              </label>
              <input 
                className="free_answer__input input pages__show-input"
                type="text" 
                name={questionId} 
                value={free_answer_value}
                onChange={handleInputChange}
                data-hide={selected_value !== "freeAnswer"}  
                readOnly={readOnly}
              />
            </>
          ) : null}
        </div>
      ))}
    </div>
  );
}
