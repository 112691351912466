//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
//Библиотеки и Api
import ApiBooking from "../../../../../app/api/authorized/account/booking/ApiBookings";
import { route } from "../../../../../inc/Routes";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import InputTextArea from "../../../../components/inputs/textarea/textarea";
import InputNumber from "../../../../components/inputs/number/number";
import ButtonLink from "../../../../components/button-link/button-link";
//Стили
import './styles.css';
import '../../../styles.css';
import DateLib from "../../../../../inc/DateLib";

export default function AccountBookingShow({ ...other }) {
    return <Page {...other} ApiModel={ApiBooking}>
        <AccountBookingShowContent {...other} />
    </Page>
}

function AccountBookingShowContent({ ...other }) {
    const contextPage = useContext(ContextPage);
    const contextContent = useContext(ContextContent);
    const [_id, set_id] = useState(null);
    const [_minutes, set_minutes] = useState('')
    const [_countPeople, set_countPeople] = useState('')
    const [_dateStart, set_dateStart] = useState(null)
    const [_dateEnd, set_dateEnd] = useState(null)
    const [_comment, set_comment] = useState('')

    const [_orderId, set_orderId] = useState('')
    const [_orderTotal, set_orderTotal] = useState('')

    const [_typeName, set_typeName] = useState('')
    const [_statusName, set_statusName] = useState('')
    const [_paymentTypeName, set_paymentTypeName] = useState('')

    const [_balanceName, set_balanceName] = useState('')

    const [_createdAt, set_createdAt] = useState(null);
    const [_updatedAt, set_updatedAt] = useState(null);
    
    useLayoutEffect(() => {
        contextContent._handleSetData.current = (data) => {
            set_id(data.id);
            set_typeName(data?.type?.name)
            set_statusName(data?.status?.name)
            set_paymentTypeName(data?.payment_type?.name)
            set_minutes(data.minutes);
            set_dateStart(data.date_start);
            set_dateEnd(data.date_end);
            set_comment(data.comment);
            set_countPeople(data.count_people);
            set_orderId(data?.order?.id);
            set_orderTotal(data?.order?.total);
            set_balanceName(data?.balance?.name);
            set_createdAt(data.created_at);
            set_updatedAt(data.updated_at);

            contextPage.setTitle(`Бронирование: ${data.id}`);
            contextContent.setTitle(`Бронирование: ${data.id}`);
            contextPage.setName(data.id);
        };
        contextContent.doLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <>
        <InputWrapper>
            <InputNumber className="pages__show-input" value={_id} readOnly={true}>id</InputNumber>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_statusName} readOnly={true}>Статус бронирования</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_paymentTypeName} readOnly={true}>Тип оплаты</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_minutes} readOnly={true}>Минуты</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputDatetime className="pages__show-input" value={_dateStart} readOnly={true}>Начало</InputDatetime>
        </InputWrapper>
        <InputWrapper>
            <InputDatetime className="pages__show-input" value={_dateEnd} readOnly={true}>Окончание</InputDatetime>
        </InputWrapper>
        <InputWrapper>
            <InputTextArea className="pages__show-input" value={_comment} readOnly={true} onChange={e => set_comment(e.target.value)}>Комментарий</InputTextArea>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_countPeople} readOnly={true}>Количество человек</InputText>
        </InputWrapper>
        {_orderId ?
            <>
                <InputWrapper>
                    <InputText className="pages__show-input" value={_orderId} readOnly={true}>
                        Номер заказа
                    </InputText>
                    <ButtonLink className="pages__show-btn-link" href={route(`authorized.admin.order.bookings.show`,{id: _orderId})}/>
                </InputWrapper>
                <InputWrapper>
                    <InputText className="pages__show-input" value={_orderTotal} readOnly={true}>Стоимость заказа</InputText>
                </InputWrapper>
            </>
        : null }
        {_balanceName ?
            <>
                <InputWrapper>
                    <InputText className="pages__show-input" value={_balanceName} readOnly={true}>Тип депозита</InputText>
                </InputWrapper>
            </>
        : null }
        <InputWrapper>
            <InputDatetime className="pages__show-input" value={_createdAt} readOnly={true}>Начало</InputDatetime>
        </InputWrapper>
        <InputWrapper>
            <InputDatetime className="pages__show-input" value={_updatedAt} readOnly={true}>Окончание</InputDatetime>
        </InputWrapper>
    </>
}
