//Реакт
import React from "react";
//Компоненты
import ButtonLink from '../button-link';
//Material UI
import AddIcon from '@mui/icons-material/Add';
//Стили
import "./styles.css";

export default function ButtonLinkAdd({...other}){

    return <ButtonLink
        {...other}
    >
        {/* <AddIcon style={{fill: "#43c691"}}/> */}
        <AddIcon style={{fill: 'rgb(80 157 207)' }}/>
    </ButtonLink>
}