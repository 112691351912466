//Реакт
import React, { useLayoutEffect, useContext, useState } from "react";
import { useLocation } from 'react-router-dom'
//Библиотеки и Api
import ApiTimes from "../../../../../app/api/authorized/account/booking/ApiTimes";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Стили
import './styles.css';

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export default function AccountTimeIndex({ ...other }) {
    return <Page {...other}>
        <AccountTimeIndexContent {...other} />
    </Page>
}

function AccountTimeIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_balanceName, set_balanceName] = useState(null)

    const query = useQuery()

    useLayoutEffect(() => {
        contextPage.setTitle(`Операции по депозиту`);
        contextContent.setTitle(`Операции по депозиту`);
        contextPage.setName(_balanceName);
        console.log(_balanceName)
    }, [contextContent, contextPage,_balanceName]);

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="minutes"
            name="minutes"
        >
            Минуты
        </ListHeaderItem>,
        <ListHeaderItem
            key="user_balance_id"
            name="user_balance_id"
        >
            ID баланса
        </ListHeaderItem>,
        <ListHeaderItem
            key="user_balance_type_name"
            name="user_balance_type_name"
        >
            Депозит
        </ListHeaderItem>,
        <ListHeaderItem
            key="type_name"
            name="type_name"
        >
            Тип операции
        </ListHeaderItem>,
        <ListHeaderItem
            key="payment_type_name"
            name="payment_type_name"
        >
            Тип оплаты
        </ListHeaderItem>,
        <ListHeaderItem
            key="status_name"
            name="status_name"
        >
            Статус оплаты
        </ListHeaderItem>,
    ];

    const filters = [];

    const renderRow = (row, i) => {
        if(!_balanceName) set_balanceName(row?.user_balance_type_name)
        return [
            <ListBodyItem className="account-times_column-width" key="id">{row.id}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="minutes">{row.minutes}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="user_balance_id">{row.user_balance_id}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="user_balance_type_name">{row.user_balance_type_name}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="type_name">{row.type_name}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="payment_type_name">{row.payment_type_name}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="status_name">{row.status_name}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiTimes}
            renderRow={renderRow}
            searchParams={{balanceId: query.get('balanceId')}}
        />
    </>
}
