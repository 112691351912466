//Реакт
import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiAnalytic from "../../../../../app/api/authorized/admin/statistic/ApiAnalytic";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import Filters from "../../../../modules/filters";
import MultiSelect from "../../../../components/inputs/milti-select";
import UniversalChart from "../../../../components/chart";
import InputDateTime from "../../../../components/inputs/datetime"
//Стили
import './styles.css';

export default function AnalyticIndex({ ...other }) {
    return (
        <Page {...other}>
            <AnalyticIndexContent {...other} />
        </Page>
    );
}

function AnalyticIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);
    const [_lineChart, set_lineChart] = useState({});
    const [_productPieChart, set_productPieChart] = useState([]);
    const [_promocodePieChart, set_promocodePieChart] = useState([]);
    
    const [_dateStart, set_dateStart] = useState('');
    const [_dateEnd, set_dateEnd] = useState('');
    const [_productIds, set_productIds] = useState([]);
    const [_paymentStatusIds, set_paymentStatusIds] = useState([]);
    const [_promocodeIds, set_promocodeIds] = useState([]);

    const handleProductsChange = (event) => {
        set_productIds(event.target.value);
    };

    const handlePaymentStatusChange = (event) => {
        set_paymentStatusIds(event.target.value);
    }

    const handlePromocodesChange = (event) => {
        set_promocodeIds(event.target.value);
    }

    const products = useSelector(state => state.adminOption.products);
    const promocodes = useSelector(state => state.adminOption.promocodes);
    const paymentStatuses = useSelector(state => state.commonOption.paymentStatuses);

    const linaeChartOptions = {
        title: 'Продажи по дням',
        hAxis: { title: 'Дата', titleTextStyle: { color: '#333' } },
        vAxis: { title: 'Сумма', titleTextStyle: { color: '#333' },  minValue: 0 },
        chartArea: { width: '85%', height: '90%' },
    };

    const productPieChartOptions = {
        title: 'Продажи по продуктам',
        chartArea: { width: '90%', height: '90%' },
    };
    const promocodePieChartOptions = {
        title: 'Продажи по промокодам',
        chartArea: { width: '90%', height: '90%' },
    };

    const prepareLineChartData = (chartData) => {
        if (!chartData) return [["Дата", "Сумма"]]; // Возвращаем пустую таблицу с заголовками
        // Преобразовываем данные в массив объектов, который можно использовать для построения линейного графика
        let data = Object.entries(chartData).map(([date, data]) => [
            date, // Дата
            data?.total ?? 0, // Общая сумма
        ]);
        // Добавляем заголовки столбцов в начало массива
        data.unshift(["Дата", "Сумма"]);
        return data;
    };
    const prepareProductPieChartData = (products) => {
        // Проверка наличия данных о продуктах
        if (!products) {
            return [["Продукт", "Продаж "]];
        }
    
        const data = [];
    
        // Итерация по ключам объекта products
        for (const key in products) {
            // Проверка наличия продукта
            if (products[key] && products[key].product != null) {
                const productName = products[key]?.product?.name + " (" + products[key]?.product?.category?.name + ")" || "Unknown";
                const totalSales = products[key].total;
                data.push([productName, totalSales]);
            }
        }
    
        // Добавляем заголовки столбцов
        data.unshift(["Product", "Total Sales"]);
    
        return data;
    };

    const preparePromocodePieChartData = (promocode) => {
        // Проверка наличия данных о продуктах
        if (!promocode) {
            return [["Промокод", "Продаж"]];
        }
    
        const data = [];
    
        // Итерация по ключам объекта products
        for (const key in promocode) {
            // Проверка наличия продукта
            if (promocode[key] && promocode[key].promocode != null) {
                const productName = promocode[key].promocode.name || "Unknown";
                const totalSales = promocode[key].total;
                data.push([productName, totalSales]);
            }
        }
    
        // Добавляем заголовки столбцов
        data.unshift(["Промокод", "Продаж"]);
    
        return data;
    };

    useLayoutEffect(() => {
        contextPage.setTitle('Аналитика');
        contextContent.setTitle('Аналитика');
    }, [contextContent, contextPage]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiIndex = ApiAnalytic.Index({dateStart: _dateStart, 
                    dateEnd: _dateEnd, products: _productIds, promocodes: _promocodeIds,
                    payments: _paymentStatusIds
                });
                const responseData = await apiIndex.getResponse();
                set_lineChart(responseData.line_chart ?? null);
                set_productPieChart(responseData.products ?? null);
                set_promocodePieChart(responseData.promocodes ?? null);
            } catch (error) {}
        };

        fetchData();
    }, [_dateStart, _dateEnd, _productIds, _promocodeIds, _paymentStatusIds]);

    const filters = [
        <InputDateTime value={_dateStart} className="input_wf" onChange={(e) => { set_dateStart(e.target.value) }}>Дата создания с </InputDateTime>,
        <InputDateTime value={_dateEnd} className="input_wf" onChange={(e) => { set_dateEnd(e.target.value) }}>Дата создания по </InputDateTime>,
        <MultiSelect options={paymentStatuses} className="input_wf" value={_paymentStatusIds} onChange={handlePaymentStatusChange}>Статус</MultiSelect>,
        <MultiSelect options={products} className="input_wf" value={_productIds} onChange={handleProductsChange}>Продукты</MultiSelect>,
        <MultiSelect options={promocodes} className="input_wf" value={_promocodeIds} onChange={handlePromocodesChange}>Промокоды</MultiSelect>,
    ]

    return (
        <>
            <Filters filters={filters}></Filters>
            <UniversalChart options={linaeChartOptions} data={prepareLineChartData(_lineChart || {})}></UniversalChart>
            <UniversalChart chartType="PieChart" options={productPieChartOptions} data={prepareProductPieChartData(_productPieChart || [])}></UniversalChart>
            <UniversalChart chartType="PieChart" options={promocodePieChartOptions} data={preparePromocodePieChartData(_promocodePieChart || [])}></UniversalChart>
        </>
    );
}