//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiRole from "../../../../../app/api/authorized/admin/user/ApiRole";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
//Стили
import './styles.css';

export default function RoleIndex({ ...other }) {
  return <Page {...other}>
    <RoleIndexContent {...other} />
  </Page>
}

function RoleIndexContent({ ...other }) {
  const contextContent = useContext(ContextContent);
  const contextPage = useContext(ContextPage);

  const [_id, set_id] = useState('');
  const [_name, set_name] = useState('');
  const [_slug, set_slug] = useState('');

  useLayoutEffect(() => {
    contextPage.setTitle(`Роли`);
    contextContent.setTitle(`Роли`);
  }, [contextContent, contextPage]);

  const header = [
    <ListHeaderItem
      key="id"
      name="id"
    >
      №
    </ListHeaderItem>,
    <ListHeaderItem
      key="name"
      name="name"
    >
      Имя
    </ListHeaderItem>,
    <ListHeaderItem
      key="slug"
      name="slug"
    >
      Слаг
    </ListHeaderItem>,
    <ListHeaderItem
      key="created_at"
      name="created_at"
    >
      Создан
    </ListHeaderItem>,
    <ListHeaderItem
      key="updated_at"
      name="updated_at"
    >
      Изменен
    </ListHeaderItem>,
    <ListHeaderItem
      key="create"
      name="create"
      isSort={false}
      className="create_mobile"
    >
      {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
    </ListHeaderItem>,
  ];

  const filters = [
    <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
    <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Имя</InputText>,
    <InputText className="input_wf" value={_slug} onChange={(e) => { set_slug(e.target.value) }}>Слаг</InputText>,
  ];

  const renderRow = (row, i) => {
    return [   
      <ListBodyItem className="admin-roles_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
      <ListBodyItem className="admin-roles_column-width" key="name" data-label={header[1].props.children}>{row.name}</ListBodyItem>,
      <ListBodyItem className="admin-roles_column-width" key="slug" data-label={header[2].props.children}>{row.slug}</ListBodyItem>,
      <ListBodyItem className="admin-roles_column-width" key="created_at" data-label={header[3].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
      <ListBodyItem className="admin-roles_column-width" key="updated_at" data-label={header[4].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
      <ListBodyItem className="admin-roles_column-width" key="show">
        {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({id: row.id})} /> : null}
      </ListBodyItem>,
    ];
  }
  return <>
    <List
      header={header}
      filters={filters}
      ApiModel={ApiRole}
      renderRow={renderRow}
      searchParams={{ id: _id, name: _name, slug: _slug }}
    />
  </>
}
