// React
import React from "react";
// Стили
import "./styles.css";
// Компоненты
import RadioAnswers from '../radio-answers/radio-answers';
import ValueInput from '../value-input/value-input';
import Label from "../../inputs/label/label";

export default function Question({ question, clientAnswer, readOnly, onOtherChange, onOtherInputChange}) {
  const displayName = question.name + (question.is_required ? '*' : '');

  return (
    <>
      {(question.is_value && !question.is_dictionary) ? 
        <ValueInput 
          questionName={displayName}
          clientAnswer={clientAnswer}
          isRequired={question.is_required}
          questionId={question.id}  
          onOtherInputChange={onOtherInputChange}
          dataType={question.data_type}
          readOnly={readOnly}
        /> 
      : null}
      {question.is_dictionary ? (
        <>
          <Label>{displayName}</Label>
          <RadioAnswers 
            answers={question.answers} 
            clientAnswer={clientAnswer}
            freeAnswer={question.is_value} 
            questionId={question.id} 
            onOtherChange={onOtherChange}
            onOtherInputChange={onOtherInputChange}
            readOnly={readOnly}
          />
        </>
      ) : null}
    </>
  );
}
