//React
import React, { useContext, useState, useEffect } from "react";
//Redux
import { useSelector, useDispatch } from 'react-redux';
// Собственные хуки
import { useErrorHandler } from "./errorHandler/errorHandler"
// Контент
import Content from "../content";
import { ProviderPage } from "./pageProvider";
import { ContextPage } from "./pageContext";
// Layout
import LayoutDefault from "../layouts/default";
// Компоненты
import MessageErrors from "../../components/message/__errors";
// СтилиuseLayoutEffect
import "./styles.css";
import { setError } from "../../../app/reducers/app/actions";

export default function PageWithProvider({ children, currentTab=null, tabs = {}, onSelectTab = () => {}, route, routes, withContent = false, ...other }) {
    const PageType = (withContent) ? WithContent : Page;
    return (
        <ProviderPage
            route={route}
            routes={routes}
        >
            <ErrorBoundary>
                <PageType onSelectTab={onSelectTab} currentTab={currentTab} tabs={tabs} {...other}>
                    {children}
                </PageType>
            </ErrorBoundary>
        </ProviderPage>
    );
}

function Page({ children, className = '', ...other }) {
    const error = useSelector(state => state.app.error);
    const [_localError, setLocalError] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (error) {
            setLocalError(error.error);
        } 
    }, [error]);

    useEffect(() => {
        if (error) {
            dispatch(setError(null)); // Сброс ошибки в Redux после установки локальной ошибки
        }
    }, [error, dispatch]);

    return (
        <div className={`page ${className}`}>
            <LayoutDefault {...other}>
                <div className="page-shell">
                    {_localError ? <MessageErrors errors={_localError} sx={{
                        width: "100%",
                        border: "solid 2px  #f5c6cb",
                        padding: "1rem",
                        borderRadius: "1rem",
                        boxSizing: "border-box",
                        backgroundColor: "#f8d7da",
                        color: "#721c24",
                        fontSize: "1rem",
                    }} /> : null}
                    {children}
                </div>
            </LayoutDefault>
        </div>
    );
}

function WithContent({ children, tabs, currentTab, onSelectTab, ApiModel, path, type, showNoId = false, devtools, withContentKeyRoute = "id", ...other }) {
    const contextPage = useContext(ContextPage);

    const handleDoCreate = (data) => {
        if (withContentKeyRoute in data)
            contextPage.goPageShow(data[withContentKeyRoute]);
        else
            contextPage.goPageShow(data.id);
    };

    const handleDoDelete = (data) => {
        contextPage.goPageIndex();
    };
    return (
        <Page devtools={devtools} breadcrumb={contextPage.breadcrumb} {...other}>
            <Content
                id={(['index', 'tree'].indexOf(type) === -1) ? contextPage.id : null}
                ApiModel={ApiModel}
                type={type}
                showNoId={showNoId}
                checkPermission={contextPage.checkPermission}
                devtools={devtools}
                tabs={tabs}
                currentTab={currentTab}
                onSelectTab={onSelectTab}
                handleDoCreate={handleDoCreate}
                handleDoDelete={handleDoDelete}
            >
                {children}
            </Content>
        </Page>
    );
}

function ErrorBoundary({ children }) {
    useErrorHandler(); // используем наш новый хук для обработки ошибок

    return children;
}