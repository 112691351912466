//Реакт
import React, { useContext, useState } from "react";
//Компоненты
import ContentHeader from "./__header";
import ContentFooter from "./__footer";
import ContentLoader from "./__loader";
import { ProviderContent } from "./contentProvider";
import { ContextContent } from "./contentContext";
import { SystemAbort404 } from "../../pages/public/system/Abort404";
import { SystemAbort403 } from "../../pages/public/system/Abort403";
//Стили
import "./styles.css";

export default function ContentWithProvider({ children, onSelectTab, currentTab, tabs, ...other }) {
    return <ProviderContent
        {...other}
    >
        <Content onSelectTab={onSelectTab} currentTab={currentTab} tabs={tabs}>
            {children}
        </Content>
    </ProviderContent>
}

function Content({ currentTab, tabs, onSelectTab, children }) {
    const { id, type, isLoad, codeApi } = useContext(ContextContent);
 
    const handleSelectTab = (tab) => {
        onSelectTab(tab)
    }

    if (codeApi === 404) return <SystemAbort404 />
    else if (codeApi === 403) return <SystemAbort403 />
    return <div className="content" data-id={(id) ? id : ''} data-type={type}>
        {isLoad ? <ContentLoader /> : null}
        <div className="tabs">
            {tabs && Object.entries(tabs).map(([key, value]) => (
                <button
                    key={key}
                    className={`tab-button ${currentTab === key ? 'active' : ''}`}
                    onClick={() => {handleSelectTab(key)}}
                >
                    {value} {/* или value.title, или любое другое свойство объекта tabs */}
                </button>
            ))}
        </div>
        <div className="content-container">
            <ContentHeader />
                <div className="content__body">
                    {children}
                </div>
            <ContentFooter />
        </div>
    </div>
}