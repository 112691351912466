import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class SurveyFormatter extends Formatter{}
export default class ApiSurvey extends ApiModelWithAuth{
    pathDef = 'authorized/admin/client/surveys';

    formatterItem = SurveyFormatter.Item;
}

