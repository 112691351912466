//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiUser from "../../../../../app/api/authorized/admin/user/ApiUser";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import InputEmail from "../../../../components/inputs/email/email";
import InputPhone from "../../../../components/inputs/phone/phone";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
import MultiSelect from "../../../../components/inputs/milti-select";
//Стили
import './styles.css';

export default function UserIndex({ ...other }) {
  return <Page {...other}>
    <UserIndexContent {...other} />
  </Page>
}

function UserIndexContent({ ...other }) {
  const contextContent = useContext(ContextContent);
  const contextPage = useContext(ContextPage);

  const [_id, set_id] = useState('');
  const [_name, set_name] = useState('');
  const [_middlename, set_middlename] = useState('');
  const [_email, set_email] = useState('');
  const [_phone, set_phone] = useState('');
  const [_surname, set_surname] = useState('');
  const [_roleIds, set_roleIds] = useState([]);

  const roles = useSelector(state => state.adminOption.roles);

  const handlRolesChange = (event) => {
    set_roleIds(event.target.value)
  }

  useLayoutEffect(() => {
    contextPage.setTitle(`Пользователи`);
    contextContent.setTitle(`Пользователи`);
  }, [contextContent, contextPage]);
  const header = [
    <ListHeaderItem
      key="id"
      name="id"
    >
      №
    </ListHeaderItem>,
    <ListHeaderItem
      key="name"
      name="name"
    >
      Имя
    </ListHeaderItem>,
    <ListHeaderItem
      key="surname"
      name="surname"
    >
      Фамилия
    </ListHeaderItem>,
    <ListHeaderItem
      key="middle_name"
      name="middle_name"
    >
      Отчество
    </ListHeaderItem>,
    <ListHeaderItem
      key="email"
      name="email"
    >
      Почта
    </ListHeaderItem>,
    <ListHeaderItem
      key="phone"
      name="phone"
    >
      Телефон
    </ListHeaderItem>,
    <ListHeaderItem
      key="created_at"
      name="created_at"
    >
      Создан
    </ListHeaderItem>,
    <ListHeaderItem
      key="updated_at"
      name="updated_at"
    >
      Изменен
    </ListHeaderItem>,
    <ListHeaderItem
      key="create"
      name="create"
      isSort={false}
      className="create_mobile"
    >
      {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
    </ListHeaderItem>,
  ];

  const filters = [
    <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
    <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Имя</InputText>,
    <InputText className="input_wf" value={_surname} onChange={(e) => { set_surname(e.target.value) }}>Фамилия</InputText>,
    <InputText className="input_wf" value={_middlename} onChange={(e) => { set_middlename(e.target.value) }}>Отчество</InputText>,
    <InputEmail className="input_wf" value={_email} onChange={(e) => { set_email(e.target.value) }}>Почта</InputEmail>,
    <InputPhone className="input_wf" useReg={false} value={_phone} onChange={(e) => { set_phone(e.target.value) }}>Телефон</InputPhone>,
    <MultiSelect className="input_wf" options={roles} value={_roleIds} onChange={handlRolesChange}>Роли</MultiSelect>,
  ];

  const renderRow = (row, i) => {
    return [
      <ListBodyItem className="admin-users_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
      <ListBodyItem className="admin-users_column-width" key="name" data-label={header[1].props.children}>{row.name}</ListBodyItem>,
      <ListBodyItem className="admin-users_column-width" key="surname" data-label={header[2].props.children}>{row.surname}</ListBodyItem>,
      <ListBodyItem className="admin-users_column-width" key="middle_name" data-label={header[3].props.children}>{row.middle_name}</ListBodyItem>,
      <ListBodyItem className="admin-users_column-width" key="email" data-label={header[4].props.children}>{row.email}</ListBodyItem>,
      <ListBodyItem className="admin-users_column-width" key="phone" data-label={header[5].props.children}>{row.phone ? '+' : ''}{row.phone}</ListBodyItem>,
      <ListBodyItem className="admin-users_column-width" key="created_at" data-label={header[6].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
      <ListBodyItem className="admin-users_column-width" key="updated_at" data-label={header[7].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
      <ListBodyItem className="admin-users_column-width" key="show">
        {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({id: row.id})} /> : null}
      </ListBodyItem>,
    ];
  }
  return <>
    <List
      header={header}
      filters={filters}
      ApiModel={ApiUser}
      renderRow={renderRow}
      searchParams={{ id: _id, roleIds: _roleIds, name: _name, middleName: _middlename, surname: _surname, email: _email, phone: _phone }}
    />
  </>
}
