//Реакт
import React, { useState, useLayoutEffect, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setFilter as setFilterApp } from "../../../app/reducers/app/actions";
//Контент
import { ContextList } from "./listContext";

export function ProviderList({ children, ApiModel, defaultSortKey = 'id', defaultSortIsAsc = false, searchParams = {}, ...other }) {
    const dispatch = useDispatch();
    const [codeApi, setCodeApi] = useState(null);
    const [list, setList] = useState([], (oldValue, newValue) => true);
    const [lastPage, setLastPage] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(100);
    const [isLoad, setIsLoad] = useState(false);
    const isShowFilters = useSelector((state) => state.app.filter);

    const [routeSearchParams, setRouteSearchParams] = useSearchParams();
    let routeOrderBy = routeSearchParams.get('orderBy');
    let routeOrderBySort = routeSearchParams.get('orderBySort');
    let routePage = routeSearchParams.get('page');
    // const [sortKey, setSortKey] = useState(routeOrderBy ? routeOrderBy : defaultSortKey);
    const [sortKey, setSortKey] = useState(defaultSortKey);
    const [sortIsAsc, setSortIsAsc] = useState(routeOrderBySort === 'desc' ? false : defaultSortIsAsc);
    const [currentPage, setCurrentPage] = useState((routePage && parseInt(routePage)) ? routePage : 1);
    const loadIndex = async ({ apiIndex }) => {
        setIsLoad(true);
        let data = await apiIndex.getResponse();
        setCodeApi(apiIndex.code);
        setCurrentPage(apiIndex.paginationCurrent > apiIndex.paginationLast ? apiIndex.paginationLast : apiIndex.paginationCurrent);
        setLastPage(apiIndex.paginationLast);
        setList(data ? data : []);
        setIsLoad(false);
    }

    useLayoutEffect(() => {
        let params = {};

        if (sortKey !== defaultSortKey) params = { ...params, orderBy: sortKey };
        if (sortIsAsc !== defaultSortIsAsc) params = { ...params, orderBySort: sortIsAsc ? 'asc' : 'desc' };
        if (sortIsAsc !== defaultSortIsAsc) params = { ...params, orderBySort: sortIsAsc ? 'asc' : 'desc' };
        if (currentPage !== 1) params = { ...params, page: currentPage };

        setRouteSearchParams(params);
    }, [sortKey, sortIsAsc, currentPage, setRouteSearchParams, defaultSortKey, defaultSortIsAsc]);
    useLayoutEffect(() => {
        let sort = {};
        sort = { orderBy: sortKey, orderBySort: (sortIsAsc) ? 'asc' : 'desc' };
        const apiIndex = ApiModel.Index({ ...searchParams, page: currentPage, ...sort });
        loadIndex({ apiIndex });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ApiModel, JSON.stringify(searchParams), currentPage, sortIsAsc, sortKey]);
    

    const _setSortKey = (name) => {
        if (name === sortKey) setSortIsAsc(!sortIsAsc);
        else {
            setSortIsAsc(true);
            setSortKey(name);
        }
    }
    return <ContextList.Provider value={{
        list, setList,
        currentPage, setCurrentPage,
        lastPage, setLastPage,
        zoomLevel, setZoomLevel,
        sortKey, setSortKey: _setSortKey,
        sortIsAsc,
        isLoad,
        codeApi,
        isShowFilters, setIsShowFilters: (value) => { dispatch(setFilterApp(value)) },
    }}>{children}</ContextList.Provider>
}