import React, { useState, useEffect } from "react";

//Компоненты
import InputWrapper from "../../input/__wrapper/input__wrapper";
import Label from "../../inputs/label/label";
import InputPhone from "../../inputs/phone/phone";
import InputEmail from "../../inputs/email/email";
import InputText from "../../inputs/text/text";
import InputDate from "../../inputs/date/date";

export default function ValueInput({ questionName, clientAnswer,  readOnly, onOtherInputChange, questionId , dataType}) {
  const [value, set_value] = useState('');
  
  useEffect(() => {
    if (clientAnswer) {
      set_value(clientAnswer.value);
      onOtherInputChange(clientAnswer.question_id, clientAnswer.value)
    } 
  }, [clientAnswer]);


  function getInput(type){
    if(type == 'text') return(
      <InputWrapper>
        <InputText 
          className="input pages__show-input" 
          name={questionId} 
          type='text'
          value={value}
          onChange={(event) => {
            onOtherInputChange(event.target.name, event.target.value);
            set_value(event.target.value);
          }} 
          readOnly={readOnly}
        >{questionName}</InputText>
      </InputWrapper>
    )
    if(type == 'email') return(
      <InputWrapper>
        <InputEmail 
          className="input pages__show-input" 
          name={questionId} 
          value={value}
          onChange={(event) => {
            onOtherInputChange(event.target.name, event.target.value);
            set_value(event.target.value);
          }}
          readOnly={readOnly}
        >{questionName}</InputEmail>
      </InputWrapper>
    )
    if(type == 'phone') return (
      <InputWrapper>
        <InputPhone 
          className="input pages__show-input" 
          name={questionId} 
          value={value}
          onChange={(event) => {
            onOtherInputChange(event.target.name, event.target.value);
            set_value(event.target.value);
          }}
          readOnly={readOnly}
          >{questionName}</InputPhone>
      </InputWrapper>
    )
    if(type == 'date') return (
      <InputWrapper>
        <InputDate 
          className="input pages__show-input" 
          name={questionId} 
          value={value}
          onChange={(event) => {
            onOtherInputChange(event.target.name, event.target.value);
            set_value(event.target.value);
          }}
          readOnly={readOnly}
          >{questionName}</InputDate>
      </InputWrapper>
    )

  }


  return getInput(dataType);
}
