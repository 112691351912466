//Реакт
import React from 'react';
//Стили
import './styles.css';

export default function Balances({ selectedBalanceId, balances = [], onChange, className = '' }) {
    const handleClick = (value) => {
        onChange(value);
    };

    const getTooltipData = (balance) => {
        const available = balance?.balance - balance?.reserved
        return (
            <div className="balances__content-item--tooltip-content">
                {available > 0 ?
                    <div> {'Доступно: ' +  available + ' мин.'}</div>
                    :
                    <div> {'Задолженность: ' +  available * (-1) + ' мин.'}</div>
                }
                {balance?.reserved ? <div>{'Резерв: ' +  balance?.reserved  + ' мин.'}</div> : null}
            </div>
        );
    };

    return (
        <div className={`balances__content ${className}`}>
            {balances.length > 0 ? balances.map((balance) => (
                <div
                    key={balance.id}
                    className={`${selectedBalanceId === balance.id ? 'balances__content-item--selected' : 'balances__content-item'}`}
                    onClick={() => handleClick(balance.id)}
                >
                    {balance.type_name}
                    <div className="balances__content-item--tooltip">{getTooltipData(balance)}</div>
                </div>
            )) : <div className="balances__content--empty">Нет доступных депозитов</div>}
        </div>
    );
}
